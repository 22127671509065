<template>
    <div class="templateSayhiTwo">
        <div class="title title_1 theme_01 tc "
            v-html="$utils.redirectUrlKeyword(templateData.languageConfig[$language].mainTitle)"> </div>
        <div class="warp">
            <div class="main">
                <div class="body-fat">
                    <div class="header flex justify-between">
                        <div class="flex-auto">{{ $t.paywall.now }}</div>
                        <div class="line"></div>
                        <div class="flex-auto">{{ $t.paywall.goal }}</div>
                    </div>
                    <div class="content flex justify-between">
                        <lottie class="lottie" :options="{
                            animationData: paywallAnimationJson,
                            loop: true,
                        }" />
                        <div class="img-warp">
                            <img class="img left" v-if="bodyFatImg1" :src="require(`@img/template/${bodyFatImg2}.png`)"
                                alt="" />
                        </div>
                        <div class="img-warp right">
                            <img class="img right" v-if="bodyFatImg2" :src="require(`@img/template/${bodyFatImg1}.png`)"
                                alt="" />
                        </div>
                    </div>
                    <div class="body-fat-list flex justify-between">
                        <div class="body-fat-item">
                            <div class="name text_2 theme_02">{{ $t.paywall.bodyFat }}</div>
                            <div class="range text_1 theme_01">{{ bodyFat1 }}</div>
                            <div class="description text_2 theme_02">{{ $t.paywall[paywallPageConfig.leveName] }}</div>
                            <div class="progress-bar">
                                <div class="progress" :class="{ active: index <= yogaLevelIndex }"
                                    v-for="(item, index) in yogaLevelList" :key="index"></div>
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="body-fat-item right">
                            <div class="name text_2 theme_02">{{ $t.paywall.bodyFat }}</div>
                            <div class="range text_1">{{ bodyFat2 }}</div>
                            <div class="description text_2 theme_02">{{ $t.paywall[paywallPageConfig.leveName] }}</div>
                            <div class="progress-bar">
                                <div class="progress" :class="{ active: index <= yogaLevelIndex + 1 }"
                                    v-for="(item, index) in yogaLevelList" :key="index"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text_4 theme_02">
                    {{ $t.paywall.results }}
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <div class="warp">
            <div class="main">

                <div class="target-info flex justify-between">
                    <div class="label">{{ $t.paywall.targetWeight }}</div>
                    <div class="label">{{ $t.paywall[paywallPageConfig.fitnessLevelLableName] }}</div>
                    <div class="value">
                        <img src="@img/icon_weight.png" class="icon" alt="" />
                        {{ getGoalWeight() }}
                    </div>
                    <div class="value">
                        <img src="@img/icon_target.png" class="icon" alt="" />
                        {{ onBoardingRecordInfo[paywallPageConfig.fitnessLevelOptionName] }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import paywallAnimationJson from "@/config/animation/paywallAnimation"; //动画json数据
import paywallPageConfig from "@/config/view.config/paywall-page"
import webConfig from "@/config/web.config";
import yogaLevelList from "@/config/onboarding.config/yogaLevelScreen";
import lottie from "vue-lottie";

export default {
    name: "templateSayhiTwo",
    data() {
        return {
            paywallAnimationJson: paywallAnimationJson,
            bodyFatImg1: null,
            bodyFatImg2: null,
            bodyFat1: 0,
            bodyFat2: 0,
            yogaLevelIndex: 0,
            yogaLevelList: [],
            paywallPageConfig: paywallPageConfig,
            onBoardingRecordInfo: this.$store.state.onBoardingRecordInfo
        };
    },
    props: {
        templateData: {
            default: () => {
                return {}
            }
        }
    },
    components: {
        lottie
    },
    created() {
        this.yogaLevelList = yogaLevelList.languageConfig[this.$language].optionList;
        let yogaLevelIndex = this.yogaLevelList.findIndex(
            (item) => item.originName == this.onBoardingRecordInfo[paywallPageConfig.fitnessLevelLableName]
        );

        this.yogaLevelIndex = Math.max(yogaLevelIndex, 0);
        this.getBodyFat(); //获取体脂率
    },
    methods: {
        getGoalWeight() {
            let weightUnit = this.onBoardingRecordInfo[this.$utils.getKey('weightUnit')];
            let targetWeightScreen = this.onBoardingRecordInfo[this.$utils.getKey('targetWeightScreen')];
            return this.$utils.getDisplayWeight(targetWeightScreen, weightUnit);
        },
        //获取体脂率
        getBodyFat() {
            let currentWeight = this.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')];
            let targetWeight = this.onBoardingRecordInfo[this.$utils.getKey('targetWeightScreen')];
            //1.当前体重的体脂率 2.目标体重的体脂率
            let currentBmi = this.$utils.calculateBMI(
                this.onBoardingRecordInfo[this.$utils.getKey('heightScreen')],
                (webConfig.weightType === 1 ? this.$utils.lbsToKg(currentWeight) : currentWeight)
            );
            let targetBmi = this.$utils.calculateBMI(
                this.onBoardingRecordInfo[this.$utils.getKey('heightScreen')],
                webConfig.weightType === 1 ? this.$utils.lbsToKg(targetWeight) : targetWeight
            );
            this.bodyFat1 = this.countBodyFat(currentBmi, 1);
            this.bodyFat2 = this.countBodyFat(targetBmi, 2);
        },
        //计算体脂率
        countBodyFat(bmi, type) {
            let result = "";
            switch (true) {
                case bmi <= 18.5:
                    type == 2
                        ? (this.bodyFatImg1 = "underweight1")
                        : (this.bodyFatImg2 = "underweight2");
                    result = `${this.$t.UNDER} ${Math.round(
                        1.2 * (18.5 - (type - 1)) + 0.23 * this.onBoardingRecordInfo[this.$utils.getKey('ageScreen')] - 5.4
                    )}%`;
                    break;
                case bmi > 18.5 && bmi <= 25:
                    type == 2 ? (this.bodyFatImg1 = "normal1") : (this.bodyFatImg2 = "normal2");
                    result = `${Math.round(
                        1.2 * (18.6 - (type - 1)) + 0.23 * this.onBoardingRecordInfo[this.$utils.getKey('ageScreen')] - 5.4
                    )}%-${Math.round(
                        1.2 * (25 - (type - 1)) + 0.23 * this.onBoardingRecordInfo[this.$utils.getKey('ageScreen')] - 5.4
                    )}%`;
                    break;
                case bmi > 25 && bmi <= 30:
                    type == 2
                        ? (this.bodyFatImg1 = "overweight1")
                        : (this.bodyFatImg2 = "overweight2");
                    result = `${Math.round(
                        1.2 * (25.1 - (type - 1)) + 0.23 * this.onBoardingRecordInfo[this.$utils.getKey('ageScreen')] - 5.4
                    )}%-${Math.round(
                        1.2 * (30 - (type - 1)) + 0.23 * this.onBoardingRecordInfo[this.$utils.getKey('ageScreen')] - 5.4
                    )}%`;
                    break;
                case bmi > 30:
                    //增重不减bmi
                    let diffBmi = type - 1;
                    if (this.onBoardingRecordInfo[this.$utils.getKey('currentWeightScreen')] -
                        this.onBoardingRecordInfo[this.$utils.getKey('targetWeightScreen')] < 0) {
                        diffBmi = 0
                    }
                    type == 2 ? (this.bodyFatImg1 = "obese1") : (this.bodyFatImg2 = "obese2");
                    result = `${this.$t.OVER} ${Math.round(
                        1.2 * (30 - (diffBmi)) + 0.23 * this.onBoardingRecordInfo[this.$utils.getKey('ageScreen')] - 5.4
                    )}%`;
                    break;
            }
            return result;
        },
    }
}
</script>

<style scoped lang="scss">
.templateSayhiTwo {

    .divider {
        margin: 20px 0;
        height: 1px;
        background: #cbcbdc;
    }

    .warp {
        background: var(--bgColor);

        .main {
            margin: 0 auto;
            width: 100%;
            max-width: 1025px;



            .target-info {
                padding: 0 5%;

                div {
                    width: 50%;
                }

                .label {
                    font-size: 19px;
                    font-weight: 500;
                    color: var(--theme_02);
                }

                .value {
                    display: flex;
                    align-items: center;
                    margin: 5px 0;
                    font-size: 22px;
                    font-weight: 600;
                    color: var(--theme_01);

                    .icon {
                        margin-right: 5px;
                        width: 20px;
                    }
                }
            }

            .body-fat {
                margin: 20px 0;
                border-radius: 12px;
                overflow: hidden;
                background: #e0e0ea;

                .header {
                    height: 48px;
                    padding: 15px 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--theme_01);

                    div {
                        text-align: center;
                    }
                }

                .content {
                    position: relative;
                    height: 300px;
                    background: #d9daee;

                    .lottie {
                        position: absolute;
                        z-index: 1;
                    }

                    @keyframes animation-translate-img-left {
                        0% {

                            opacity: 0;
                            transform: translateX(-200px);
                        }

                        100% {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }

                    @keyframes animation-translate-img-right {
                        0% {

                            opacity: 0;
                            transform: translateX(200px);
                        }

                        100% {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }

                    .img-warp {
                        position: relative;
                        z-index: 9;
                        padding-top: 5px;
                        width: 50%;
                        min-width: 50%;
                        height: 100%;

                        .img {
                            display: block;
                            margin: 0 auto;
                            height: 100%;
                        }

                        .img.left {
                            animation: animation-translate-img-left 1.5s ease-in-out 0s
                        }

                        .img.right {
                            animation: animation-translate-img-right 1.5s ease-in-out 0s
                        }

                    }
                }

                .body-fat-list {
                    .line {
                        margin-top: 30px;
                        height: 100px;
                    }

                    .body-fat-item {
                        padding: 16px 30px;
                        width: 49.5%;

                        .description {
                            margin-top: 16px;
                        }

                        .range {
                            margin-top: 5px;
                        }

                        .progress-bar {
                            display: flex;

                            .progress {
                                margin: 10px 2px;
                                flex: 1;
                                height: 5px;
                                background: #edeff5;
                                border-radius: 48px;
                            }

                            .active {
                                background: var(--theme_01);
                            }
                        }
                    }

                    .right {
                        .range {
                            color: #7249c9;
                        }

                        .progress-bar {
                            .active {
                                background: #7249c9;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        .warp .main {
            .target-info {
                display: flex;

                .label {
                    font-size: 14px;

                }

                .icon {
                    width: 15px;
                }

                .value {
                    font-size: 15px;
                }
            }


        }

    }
}
</style>